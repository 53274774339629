.react-contextmenu-item:focus {
	outline: none !important;
	outline-width: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.react-contextmenu-item.react-contextmenu-item--selected {
	background: linear-gradient(to top, #555, #444);
	color: #e9e9e9;
}

.react-contextmenu-item.react-contextmenu-item--selected:hover {
	background: linear-gradient(to top, #555, #333);
	color: white;
}

.react-contextmenu-item.react-contextmenu-item--selected:active {
	background: linear-gradient(to top, #555, #444);
	color: #e9e9e9;
}

.react-contextmenu-item.react-contextmenu-item--selected:disabled {
	pointer-events: none;
	color: #999999;
}


.zoomIt {
	display: block !important;
	-webkit-transition: -webkit-transform 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out;
	-o-transition: -o-transform 0.2s ease-out;
	-ms-transition: -ms-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
}

.zoomIt:hover {
	-moz-transform: scale(1.05);
	-webkit-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05)
}

.zoomItHover {
	-moz-transform: scale(1.05);
	-webkit-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05)
}

@media (max-width: 399px) {
	.acceso-directo-label {
		font-size: 10px;
	}
}

@media (min-width: 400px) and (max-width: 575px) {
	.acceso-directo-label {
		font-size: 10px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.acceso-directo-label {
		font-size: 14px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.acceso-directo-label {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.acceso-directo-label {
		font-size: 16px;
	}
}

@media (min-width: 1200px) {
	.acceso-directo-label {
		font-size: 16px;
	}
}
