.content-image {
	height: calc(100vh - 170px) !important;
}

@media (max-width: 399px) {
	.bg-logo-svg {
		background-size: 150px auto !important;
		background-position: center 20% !important;
	}
	.top-actions {
		margin-top: 100px !important;
	}
}

@media (min-width: 400px) and (max-width: 575px) {
	.bg-logo-svg {
		background-size: 150px auto !important;
		background-position: center 20% !important;
	}
	.top-actions {
		margin-top: 100px !important;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.bg-logo-svg {
		background-size: 200px auto !important;
		background-position: center 25% !important;
	}
	.top-actions {
		margin-top: 150px !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.bg-logo-svg {
		background-size: 200px auto !important;
		background-position: center 25% !important;
	}
	.top-actions {
		margin-top: 150px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.bg-logo-svg {
		background-size: 300px auto !important;
		background-position: center 35% !important;
	}
	.top-actions {
		margin-top: 400px !important;
	}
}

@media (min-width: 1200px) {
	.bg-logo-svg {
		background-size: 300px auto !important;
		background-position: center 35% !important;
	}
	.top-actions {
		margin-top: 300px !important;
	}
}
